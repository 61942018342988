import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import emailjs from 'emailjs-com';

const Contact = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);

  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");

  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errMessages, setErrMessages] = useState("");

  const [successMsg, setSuccessMsg] = useState("");

  const handleName = (e) => {
    setClientName(e.target.value);
    setErrClientName("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handleMessages = (e) => {
    setMessages(e.target.value);
    setErrMessages("");
  };

  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };

  const handlePost = (e) => {
    e.preventDefault();
    if (!clientName) {
      setErrClientName("Enter your Name");
    }
    if (!email) {
      setErrEmail("Enter your Email");
    } else {
      if (!EmailValidation(email)) {
        setErrEmail("Enter a Valid Email");
      }
    }
    if (!messages) {
      setErrMessages("Enter your Messages");
    }
    if (clientName && email && EmailValidation(email) && messages) {
      const responseData = {
        from_name: clientName,
        reply_to: email,
        message: messages,
      };

      emailjs.send(
        'service_ewvkeef', // Replace with your EmailJS service ID
        'template_o4oc9ti', // Replace with your EmailJS template ID
        responseData,
        'QUJcvT5xjOeoPmPwD' // Replace with your EmailJS user ID
      ).then(response => {
        //alert('Email sent successfully', response.status, response.text);
        setSuccessMsg(`Thank you dear ${clientName}, Your messages have been received successfully. Further details will be sent to you by your email at ${email}.`);
        setClientName("");
        setEmail("");
        setMessages("");
      }).catch(err => {
        console.error('Failed to send email. Error: ', err);
      });
    }
  };

  return (
    <div className="px-4 mx-auto max-w-container">
      <Breadcrumbs title="Contact" prevLocation={prevLocation} />
      {successMsg ? (
        <p className="pb-20 font-medium text-green-500 w-96">{successMsg}</p>
      ) : (
        <form className="pb-20">
          <h1 className="text-3xl font-semibold font-titleFont">
            Fill up a Form
          </h1>
          <div className="w-[50%] h-auto py-6 flex flex-col gap-6">
            <div>
              <p className="px-2 text-base font-semibold font-titleFont">
                Name
              </p>
              <input
                onChange={handleName}
                value={clientName}
                className="w-[50%] px-2 py-1 text-base font-medium border-b-2 outline-none placeholder:font-normal placeholder:text-sm focus-within:border-primeColor"
                type="text"
                placeholder="Enter your name here"
              />
              {errClientName && (
                <p className="flex items-center gap-1 px-2 mt-1 text-sm font-semibold text-red-500 font-titleFont">
                  <span className="text-sm italic font-bold">!</span>
                  {errClientName}
                </p>
              )}
            </div>
            <div>
              <p className="px-2 text-base font-semibold font-titleFont">
                Email
              </p>
              <input
                onChange={handleEmail}
                value={email}
                className="w-[50%] px-2 py-1 text-base font-medium border-b-2 outline-none placeholder:font-normal placeholder:text-sm focus-within:border-primeColor"
                type="email"
                placeholder="Enter your email here"
              />
              {errEmail && (
                <p className="flex items-center gap-1 px-2 mt-1 text-sm font-semibold text-red-500 font-titleFont">
                  <span className="text-sm italic font-bold">!</span>
                  {errEmail}
                </p>
              )}
            </div>
            <div>
              <p className="px-2 text-base font-semibold font-titleFont">
                Messages
              </p>
              <textarea
                onChange={handleMessages}
                value={messages}
                cols="30"
                rows="3"
                className="w-[50%] px-2 py-1 text-base font-medium border-b-2 outline-none resize-none placeholder:font-normal placeholder:text-sm focus-within:border-primeColor"
                type="text"
                placeholder="Enter your message here"
              ></textarea>
              {errMessages && (
                <p className="flex items-center gap-1 px-2 mt-1 text-sm font-semibold text-red-500 font-titleFont">
                  <span className="text-sm italic font-bold">!</span>
                  {errMessages}
                </p>
              )}
            </div>

            <button
              onClick={handlePost}
              className="h-10 text-base font-semibold tracking-wide text-gray-200 duration-200 w-44 bg-primeColor font-titleFont hover:bg-black hover:text-white"
            >
              Post
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Contact;
