import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  abu_Dhabi_City1,
  abu_Dhabi_City2,
  abu_Dhabi_City3,
  abu_Dhabi_City4,


  desert_Safari1,
  desert_Safari2,
  desert_Safari3,
  desert_Safari4,

  dubai_city1,
  dubai_city2,
  dubai_city3,
  dubai_city4,
  car1,
  car2,
  car3,
  car4,
} from "../../../assets/images/index";

const BestSellers = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Our Bestsellers" />
      <div className="grid w-full grid-cols-1 gap-10 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4">
        <Product
          _id="1011"
          img= {dubai_city1}
          img2= {dubai_city2}
          img3= {dubai_city3}
          img4={dubai_city4}
          productName= "Dubai City Tour Sharing"
          price= "150.00"
          color= "City Tour"
          badge= {true}
          des="Explore the wonders of Dubai with our exclusive tour package, offering an unforgettable journey through the heart of the UAE. Starting with pickup in a car, this tour covers historic and modern attractions. Highlights include: Old Dubai & Dubai Museum, Drive through Sheikh Zayed Road, Burj-al-Arab & Jumeirah Beach, Meena Bazar & Creek Side View, Atlantis The Palm Island, Souk Madinat Jumeirah, Jumeirah Mosque, Dubai Downtown, La-Mer Beach, King's Palace, Heritage Village, Dubai Marina, and Burj Khalifa."   />
        <Product
          _id="1012"
          img= {abu_Dhabi_City1}
          img2= {abu_Dhabi_City2}
          img3= {abu_Dhabi_City3}
          img4={abu_Dhabi_City4}
          productName="Abu Dhabi City Tour Sharing"
          price="200.00"
          color="City Tour"
          badge={true}
          des="Consider the item packaging traditionally seen in brick-and-mortar stores."
        />
        <Product
          _id="1013"
          img= {desert_Safari1}
          img2= {desert_Safari2}
          img3= {desert_Safari3}
          img4={desert_Safari4}
          productName="Desert Safari Sharing"
          price="250.00"
          color="Safari"
          badge={true}
          des="Begin your adventure with a pickup from your location in a comfortable V8 Car. ⚫ Experience the thrill of Dune Bashing, navigating the towering sand dunes at high speed. ⚫ Enjoy an Arabic dress photoshoot in the picturesque desert.⚫Take a tranquil Camel Ride, experiencing the desert like a true Bedouin.⚫ Optional Quad Bike ride (available at an additional cost). ⚫ Feast on an International and BBQ Buffet Dinner with both vegetarian and non-vegetarian options.⚫ Unlimited soft drinks and mineral water throughout the tour.⚫Optional Shisha session at your table (available at an additional cost).⚫Enjoy captivating performances including a Tanoura Show, Fire Show, and Belly Dance.⚫ Return drop-off to your location.⚫Limited to 6 passengers per car for a personalized experience."/>
        <Product
          _id="1014"
          img= {car1}
          img2= {car2}
          img3= {car3}
          img4={car4}
          productName="Private Car with Driver Full Day"
          price="700.00"
          color="Transport"
          badge={true}
          des="Enjoy a full day of luxury and convenience with a private car and professional chauffeur at your disposal.⚫Perfect for a day of shopping, business meetings, exploring the city's sights, or a stress-free journey.⚫Customize your itinerary, make multiple stops, and explore at your own pace.⚫Our priority is your comfort, convenience, and satisfaction.⚫Experience unparalleled luxury, professionalism, and punctuality with Irha Tourism.⚫Begin your adventure with a pickup from your location in a comfortable V8 Car.⚫Experience the thrill of Dune Bashing, navigating the towering sand dunes at high speed.⚫Enjoy an Arabic dress photoshoot in the picturesque desert.⚫Take a tranquil Camel Ride, experiencing the desert like a true Bedouin.⚫Optional Quad Bike ride (available at an additional cost).⚫Feast on an International and BBQ Buffet Dinner with both vegetarian and non-vegetarian options.⚫Unlimited soft drinks and mineral water throughout the tour.⚫Optional Shisha session at your table (available at an additional cost).⚫Enjoy captivating performances including a Tanoura Show, Fire Show, and Belly Dance.⚫Return drop-off to your location.⚫Limited to 6 passengers per car for a personalized experience.    " />
      </div>
    </div>
  );
};

export default BestSellers;
