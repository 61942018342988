import React from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";

const ProductInfo = ({ productInfo }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-4xl font-semibold">{productInfo.productName}</h2>
      <p className="text-xl font-semibold">{productInfo.price}AED</p>
      <p className="text-base text-gray-600">{productInfo.des}</p>
      <p className="text-sm">For bookings, call or message us on WhatsApp at 00971 50 799 9284. Don't miss out on the opportunity to create unforgettable memories with Irha Tourism!</p>
      <p className="text-lg font-medium">
        <span className="font-normal">Category :</span> {productInfo.color}
      </p>
      <button
        onClick={() =>
          dispatch(
            addToCart({
              _id: productInfo.id,
              name: productInfo.productName,
              quantity: 1,
              image: productInfo.img,
              image2: productInfo.img2,
              badge: productInfo.badge,
              price: productInfo.price,
              colors: productInfo.color,
            })
          )
        }
        className="w-full py-4 text-lg text-white duration-300 bg-primeColor hover:bg-black font-titleFont"
      >
        Add to Cart
      </button>

    </div>
  );
};

export default ProductInfo;
