import React from "react";
import { Link } from "react-router-dom";
import { productOfTheYear } from "../../../assets/images";
import ShopNow from "../../designLayouts/buttons/ShopNow";
import Image from "../../designLayouts/Image";

const YearProduct = () => {
  return (

      <div className="w-full h-80 mb-20 bg-[#f3f3f3] md:bg-transparent relative font-titleFont">
        <Image
          className="hidden object-cover w-full h-full md:inline-block"
          imgSrc={productOfTheYear}
        />
        <div className="absolute top-0 right-0 flex flex-col items-start justify-center w-full gap-6 px-4 md:w-2/3 xl:w-1/2 h-80 md:px-0">
          <h1 className="text-3xl font-semibold text-white">
            Get Your Tour
          </h1>
          <p className="text-base font-normal text-white max-w-[600px] mr-4">
          Your Gateway to the Best Experiences in the UAE
          </p>
          <Link to="/shop">
          <ShopNow />
          </Link>
        </div>
      </div>

  );
};

export default YearProduct;
