import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { resetCart } from "../../redux/orebiSlice";
import { emptyCart } from "../../assets/images/index";
import ItemCard from "./ItemCard";

const Cart = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.orebiReducer.products);
  const [totalAmt, setTotalAmt] = useState("");
  const [Name, setName] = useState("");
  const [Hotel, setHotel] = useState("");
  const [Number, setNumber] = useState("");
  const handleName = (e) => {
    setName(e.target.value);

  };
  const handleHotel = (e) => {
    setHotel(e.target.value);

  };
  const handleNumber = (e) => {
    setNumber(e.target.value);

  };

  useEffect(() => {
    let price = 0;
    products.map((item) => {
      price += item.price * item.quantity;
      return price;
    });
    setTotalAmt(price);
  }, [products]);

  const sendToWhatsApp=()=> {
    let message = "New Order:\n\n";
    message+= "Customer Name  : " + Name + "\n"+ "\n";
    message+= "Customer Number  : " + Number + "\n"+ "\n";
    message+= "Customer Hotel Name  : " + Hotel + "\n"+ "\n";
    products.forEach((item) => {
      message += "Tour: " + item.name + "\n";
      message += "Persons: " + item.quantity + "\n";
      message += "Price: " + item.quantity * item.price + "\n"+"\n";
    });
    message+= "Total Price: " + totalAmt + "\n";
    let whatsappURL = "https://wa.me/971568142771/?text=" + encodeURIComponent(message);

    window.open(whatsappURL);
  }

  return (
    <div className="px-4 mx-auto max-w-container">
      <Breadcrumbs title="Cart" />
      {products.length > 0 ? (
        <div className="pb-20">
          <div className="w-full h-20 bg-[#F5F7F7] text-primeColor hidden lgl:grid grid-cols-5 place-content-center px-6 text-lg font-titleFont font-semibold">
            <h2 className="col-span-2">Tours</h2>
            <h2>Price</h2>
            <h2>Persons</h2>
            <h2>Sub Total</h2>
          </div>
          <div className="mt-5">
            {products.map((item) => (
              <div key={item._id}>
                <ItemCard item={item} />
              </div>
            ))}
          </div>

          <button
            onClick={() => dispatch(resetCart())}
            className="px-10 py-2 mb-4 font-semibold text-white uppercase duration-300 bg-red-500 hover:bg-red-700"
          >
            Reset cart
          </button>


          <div className="flex justify-end gap-4 mt-4 max-w-7xl">
            <div className="flex flex-col gap-4 w-96">
              <h1 className="text-2xl font-semibold text-right">Cart totals</h1>
              <div>
              <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
              <div className="flex items-center w-[90%]">
              <input
                className="h-8 px-4 text-sm border border-gray-400 outline-none w-[100%] mdl:w-[100%] text-primeColor"
                type="text"
                placeholder="Please Add Your Name"
                onChange={handleName}
                value={Name}
              />

            </div>
            </p>
            <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
              <div className="flex items-center w-[90%]">
              <input
                className="h-8 px-4 text-sm border border-gray-400 outline-none w-[100%] mdl:w-[100%] text-primeColor"
                type="text"
                placeholder="Please Add Your Hotel Name "
                onChange={handleHotel}
                value={Hotel}
              />

            </div>
            </p>
            <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
              <div className="flex items-center w-[90%]">
              <input
                className="h-8 px-4 text-sm border border-gray-400 outline-none w-[100%] mdl:w-[100%] text-primeColor"
                type="text"
                placeholder="Please Add Your Contect Number"
                onChange={handleNumber}
                value={Number}
              />

            </div>
            </p>
                <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
                  Subtotal
                  <span className="font-semibold tracking-wide font-titleFont">
                    {totalAmt}AED
                  </span>
                </p>

                <p className="flex items-center justify-between border-[1px] border-gray-400 py-1.5 text-lg px-4 font-medium">
                  Total
                  <span className="text-lg font-bold tracking-wide font-titleFont">
                    {totalAmt}AED
                  </span>
                </p>
              </div>
              <div className="flex justify-end">
                <Link to="/" onClick={() => dispatch(resetCart())}>
                  <button onClick={() => sendToWhatsApp()} className="h-10 text-white duration-300 w-52 bg-primeColor hover:bg-black">
                    Proceed to Checkout
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col items-center justify-center gap-4 pb-20 mdl:flex-row"
        >
          <div>
            <img
              className="p-4 mx-auto rounded-lg w-80"
              src={emptyCart}
              alt="emptyCart"
            />
          </div>
          <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
            <h1 className="text-xl font-bold uppercase font-titleFont">
              Your Cart feels lonely.
            </h1>
            <p className="px-10 -mt-2 text-sm text-center">
              Your Shopping cart lives to serve. Give it purpose - fill it with
              books, electronics, videos, etc. and make it happy.
            </p>
            <Link to="/">
              <button onClick={() => dispatch(resetCart())} className="px-8 py-2 text-lg font-semibold text-gray-200 duration-300 rounded-md cursor-pointer bg-primeColor hover:bg-black active:bg-gray-900 font-titleFont hover:text-white">
                Continue Shopping
              </button>
            </Link>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Cart;
