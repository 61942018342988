import React from "react";
// import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {


  farari1,
  farari2,
  farari3,
  farari4,

  dubai_frame1,
  dubai_frame2,
  dubai_frame3,
  dubai_frame4,
  louvre_Museum1,
  louvre_Museum2,
  louvre_Museum3,
  louvre_Museum4,
  miracle_garden1,
  miracle_garden2,
  miracle_garden3,
  miracle_garden4,
  // dubai_city1,
  // dubai_city2,
  // dubai_city3,
  // dubai_city4,
} from "../../../assets/images/index";

import './new.css'
// import { Carousel } from "@material-tailwind/react";
const NewArrivals = () => {

  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4,
  //   slidesToScroll: 1,
  //   nextArrow: <div style={{ display: 'none' }} />,
  //   prevArrow: <div style={{ display: 'none' }} />,
  //   responsive: [
  //     {
  //       breakpoint: 1025,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         infinite: true,
  //       },
  //     },
  //     {
  //       breakpoint: 769,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         infinite: true,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: true,
  //       },
  //     },
  //   ],
  // };
  return (
    <div className="w-full pb-16 slider-container">
      <Heading heading="Tours" />
      <div className="grid w-full grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4">
        <div >
          <Product
            _id="100001"
            img={farari1}
            img2={farari2}
            img3={farari3}
            img4={farari4}

            productName="Ferrari World"
            price="295.00"
            color="Park"
            badge={false}
            des="Experience the Thrill of Ferrari World Abu Dhabi\n\nDiscover the exhilaration of Ferrari World Abu Dhabi, an indoor amusement park located on the vibrant Yas Island. Perfect for families seeking excitement and adrenaline-fueled fun, this ultimate playground brings the iconic Ferrari experience to life.\n\nUnleash Your Inner Speed Demon\nAt Ferrari World, prepare to experience the thrilling rush of speed like never before. Imagine the sheer exhilaration of accelerating to 250 km/h in just five seconds on the world's fastest roller coaster. It's a heart-pounding adventure that every thrill-seeker must experience.\n\nFulfill Your Ferrari Dreams\nEver dreamt of driving a Ferrari? At Ferrari World, that dream can become a reality. Buckle up and take a spin around the track with your family or alongside a Ferrari co-pilot, and feel the power of the prancing horse beneath you.\n\nFun for the Whole Family\nFerrari World isn’t just for adults—it’s the perfect destination for families. With a plethora of activities and adventures designed to keep kids entertained, there’s something for everyone to enjoy. From the Speed of Magic 4D movie to numerous outstanding shows and attractions, Ferrari World guarantees endless fun and excitement for visitors of all ages.\n\nBook Your Unforgettable Experience with Irha Tourism\nContact Irha Tourism to book your perfect experience at Ferrari World Abu Dhabi. Our unbeatable tour inclusions ensure a seamless and memorable visit:\n\nGeneral Admission ticket to Ferrari World Abu Dhabi\nAccess to 25 racing-themed rides, shows, and attractions\nPick-up & Drop-off Facility (if transportation option selected)\n"
          />

        </div>

        <div >
          <Product
            _id="100002"
            img={miracle_garden1}
            img2={miracle_garden2}
            img3={miracle_garden3}
            img4={miracle_garden4}
            productName="Miracle Garden Dubai"
            price="99.00"
            color="Park"
            badge={false}
            des="Experience the beauty of Dubai's Miracle Garden with over 45 million flowers.⚫Enjoy expertly curated trips to witness mesmerizing displays.⚫Private transfer options available upon request.⚫Entrance ticket to Miracle Garden.⚫Panoramic view of over 45 million flowers.⚫Private transfer upon request."
          />
        </div>
        <div >
          <Product
            _id="100003"
            img={dubai_frame1}
            img2={dubai_frame2}
            img3={dubai_frame3}
            img4={dubai_frame4}
            productName="Dubai Frame"
            price="55.00"
            color="Park"
            badge={false}
            des="Experience the awe-inspiring Dubai Frame, a monumental structure that encapsulates the past, present, and future of this vibrant city. Located in the heart of Dubai at Zabeel Park, this architectural marvel stands as a symbol of innovation and progress. Admission includes access to the Dubai Past Gallery, 360-degree views from the 150-meter high Sky Deck, walking across the Glass Bridge, and the Virtual Metropolis experience. The Dubai Frame offers panoramic views of both the historic old Dubai and the glittering new Dubai, including iconic landmarks like the Burj Khalifa. For bookings and inquiries, contact us via call or WhatsApp at 00971 50 799 9284. Tour Inclusions: Admission ticket to the spectacular Dubai Frame, Explore the Dubai Past Gallery, Enjoy 360-degree views from the 150-meter high Sky Deck, Walk across the breathtaking Glass Bridge, Peer into Dubai's future with the Virtual Metropolis experience, Pick-up & Drop Off Facility (Private Transportation option available). Additional Options: Customize your Dubai Frame experience with additional offerings. Additional Information: Venue Address: Zabeel Park Jogging Track, Za'abeel, Al Kifaf, Dubai, United Arab Emirates. Please ensure you bring a valid Photo ID/passport for entrance. Timings for transfers may vary based on traffic conditions and your location. Pets, food, and beverage are not permitted at the Dubai Frame. Children under 12 must be accompanied by an adult. Children under three and people with disabilities, along with two companions, enjoy free access to the Dubai Frame. Opening Hours: 09:00 AM to 09:00 PM (Everyday)."
          />
        </div>
        <div >
          <Product
            _id="100004"
            img={louvre_Museum1}
            img2={louvre_Museum2}
            img3={louvre_Museum3}
            img4={louvre_Museum4}
            productName="Louvre Museum"
            price="100.00"
            color="Mussium"
            badge={false}
            des="Experience the extraordinary Louvre Abu Dhabi Universal Museum, a defining cultural landmark in Abu Dhabi and the UAE. The museum showcases artifacts from diverse civilizations, fostering deep understanding and reflection. Marvel at the awe-inspiring architecture, resembling a floating ivory structure on the tranquil expanse of the Persian Gulf. Encounter an array of art classics and masterpieces by globally acclaimed artists like Leonardo Da Vinci and Vincent Van Gogh. Tour Highlights: Access to the inaugural Universal Museum of the Arabian world Marvel at permanent exhibits tracing back to the 3rd century BC Witness classic masterpieces on loan from esteemed French institutions Transfers available (If chosen as an option) Essential Information: Kindly ensure you carry a valid ID or Passport during your visit. Child tickets are complimentary but require booking. Please Note: The Louvre Abu Dhabi is closed on Mondays."
          />
        </div>

      </div>
    </div>
  );
};

export default NewArrivals;
