import React from "react";


const Breadcrumbs = ({  title }) => {

  return (
    <div className="flex flex-col w-full gap-3 py-10 xl:py-10">
      <h1 className="text-5xl font-bold text-primeColor font-titleFont">
        {title}
      </h1>

    </div>
  );
};

export default Breadcrumbs;
