import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import Slider from "react-slick";
const ProductDetails = () => {
  const location = useLocation();
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <div style={{ display: 'none' }} />,
    prevArrow: <div style={{ display: 'none' }} />,

  };
  const [productInfo, setProductInfo] = useState([]);

  useEffect(() => {
    setProductInfo(location.state.item);

  }, [location, productInfo]);
  console.log(productInfo)

  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
      <div className="p-4 mx-auto max-w-container">

        <div className="grid grid-cols-1 gap-4 p-4 pt-8 pb-10 -mt-5 bg-gray-100 h-100 w- md:grid-cols-2 xl:grid-cols-6 xl:-mt-8">
          <div className="w-[110%] h-[70%] xl:col-span-2 p-6">
          <Slider {...settings} className="object-fit h-80">
              <img className="object-cover w-full h-80" src={productInfo.img} alt={productInfo.img} />
              <img className="object-cover w-full h-80" src={productInfo.img2} alt={productInfo.img2} />
              <img className="object-cover w-full h-80" src={productInfo.img3} alt={productInfo.img3} />
              <img className="object-cover w-full h-80" src={productInfo.img4} alt={productInfo.img4} />

              </Slider>
          </div>

          <div className="flex flex-col justify-center w-full h-full gap-6 md:col-span-2 xl:col-span-3 xl:py-10 xl:px-14">
            <ProductInfo productInfo={productInfo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;



