import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="px-12 mx-auto max-w-container">
      <Breadcrumbs title="About" prevLocation={prevLocation} />
      <div className="pb-0">
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-xl font-bold text-primeColor">Irha Tourism</span>{" "}
          is a premier travel brand, renowned for delivering exceptional tourism experiences in the UAE. Celebrated for our seamless blend of luxury and adventure, we specialize in offering personalized city tours, thrilling desert safaris, and easy access to top attractions. Our top-notch car rental services, featuring a wide selection of high-quality vehicles, ensure that your journey is both comfortable and convenient. At Irha Tourism, we are dedicated to making your visit to the UAE unforgettable, combining world-class service with the essence of Arabian hospitality.
        </h1>

      </div>
      <div className="pb-5 pl-5">
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-xl font-bold text-primeColor">Our Services</span>
        </h1>

        At Irha Tourism, we provide a comprehensive range of services to cater to all your travel needs:
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-lg font-semibold text-primeColor">City Tours</span>
        </h1>
        Explore the iconic cities of Dubai and Abu Dhabi with our expertly guided tours. Discover modern marvels, cultural landmarks, and hidden gems.
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-lg font-semibold text-primeColor">Desert Safaris</span>
        </h1>
        Embark on an exhilarating adventure in the Arabian desert with our dune bashing, camel rides, and traditional Bedouin experiences.
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-lg font-semibold text-primeColor">Attraction Tickets</span>
        </h1>
        Gain easy access to the UAE’s top attractions, including the Dubai Frame and Ski Dubai, with our hassle-free ticketing services.
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-lg font-semibold text-primeColor">Car Rentals</span>
        </h1>
        Choose from our fleet of high-quality vehicles for full-day, half-day, airport transfers, or extended rentals. We offer a variety of cars, from luxury sedans to spacious buses, tailored to your needs.
      </div>
      <div className="pb-5 pl-5">
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-xl font-bold text-primeColor">Why Choose Us?</span>
        </h1>

        At Irha Tourism, we are committed to providing exceptional service and unforgettable experiences. Here’s why you should choose us:
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-lg font-semibold text-primeColor">Quality</span>
        </h1>
        We offer only the best in tours, tickets, and transportation, ensuring your experience is top-notch.
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-lg font-semibold text-primeColor">Convenience</span>
        </h1>
        Our easy booking process and flexible options make planning your trip simple and stress-free.
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-lg font-semibold text-primeColor">Customer Service</span>
        </h1>
        Our dedicated team is always ready to assist you, ensuring that every aspect of your experience is handled with care and professionalism.
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-lg font-semibold text-primeColor">Personalization</span>
        </h1>
        We tailor our services to meet your specific needs and preferences, ensuring a unique and memorable travel experience.
      </div>
      <div className="flex flex-col pb-10">
      <div className="pb-5">
      Explore the UAE with confidence, knowing that Irha Tourism is here to guide you every step of the way. Whether you’re a solo traveler, a couple, or a group, we are committed to making your stay in the UAE truly exceptional.
      </div>
      <Link to="/shop">
        <button className="h-10 text-white duration-300 w-52 bg-primeColor hover:bg-black">
         Lets Enjoy
        </button>
      </Link>
      </div>

    </div>
  );
};

export default About;
