import {

  //toursdata
  farari1,
  farari2,
  farari3,
  farari4,

  yas_Waterworld1,
  yas_Waterworld2,
  yas_Waterworld3,
  yas_Waterworld4,

  dubai_frame1,
  dubai_frame2,
  dubai_frame3,
  dubai_frame4,

  louvre_Museum1,
  louvre_Museum2,
  louvre_Museum3,
  louvre_Museum4,


  abu_Dhabi_City1,
  abu_Dhabi_City2,
  abu_Dhabi_City3,
  abu_Dhabi_City4,

  car1,
  car2,
  car3,
  car4,

  desert_Safari1,
  desert_Safari2,
  desert_Safari3,
  desert_Safari4,

  dubai_city1,
  dubai_city2,
  dubai_city3,
  dubai_city4,



  future_m1,
  future_m2,
  future_m3,
  future_m4,

  global1,
  global2,
  global3,
  global4,


  miracle_garden1,
  miracle_garden2,
  miracle_garden3,
  miracle_garden4,

  ski_dubai1,
  ski_dubai2,
  ski_dubai3,
  ski_dubai4,

  warner_bros1,
  warner_bros2,
  warner_bros3,
  warner_bros4,

  wild_wadi1,
  wild_wadi4,
  wild_wadi3,
  wild_wadi2,
} from "../assets/images/index";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Tours",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "About",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Contact",
    link: "contact",
  },

];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: "201",
    img:  dubai_city1,
    img2: dubai_city2,
    img3: dubai_city3,
    img4: dubai_city4,
    productName: "Dubai City Tour Sharing",
    price: "150.00",
    color: "City Tour",
    badge: true,
    des:"Explore the wonders of Dubai with our exclusive tour package, offering an unforgettable journey through the heart of the UAE. Starting with pickup in a car, this tour covers historic and modern attractions. Highlights include: Old Dubai & Dubai Museum, Drive through Sheikh Zayed Road, Burj-al-Arab & Jumeirah Beach, Meena Bazar & Creek Side View, Atlantis The Palm Island, Souk Madinat Jumeirah, Jumeirah Mosque, Dubai Downtown, La-Mer Beach, King's Palace, Heritage Village, Dubai Marina, and Burj Khalifa.",
 },
  {
    _id: "202",
    img:  desert_Safari3,
    img2: desert_Safari4,
    img3: desert_Safari1,
    img4: desert_Safari2,
    productName: "Desert Safari Sharing",
    price: "250.00",
    color: "Safari",
    badge: true,
    des: "Begin your adventure with a pickup from your location in a comfortable V8 Car. ⚫ Experience the thrill of Dune Bashing, navigating the towering sand dunes at high speed. ⚫ Enjoy an Arabic dress photoshoot in the picturesque desert.⚫Take a tranquil Camel Ride, experiencing the desert like a true Bedouin.⚫ Optional Quad Bike ride (available at an additional cost). ⚫ Feast on an International and BBQ Buffet Dinner with both vegetarian and non-vegetarian options.⚫ Unlimited soft drinks and mineral water throughout the tour.⚫Optional Shisha session at your table (available at an additional cost).⚫Enjoy captivating performances including a Tanoura Show, Fire Show, and Belly Dance.⚫ Return drop-off to your location.⚫Limited to 6 passengers per car for a personalized experience.",
   },
  {
    _id: "203",
    img:  abu_Dhabi_City1,
    img2: abu_Dhabi_City4,
    img3: abu_Dhabi_City3,
    img4: abu_Dhabi_City2,
    productName: "Abu Dhabi City Tour Sharing",
    price: "200.00",
    color: "City Tour",
    badge: true,
    des: "Begin your adventure with a pickup from your location in a comfortable V8 Car.⚫Experience the thrill of Dune Bashing, navigating the towering sand dunes at high speed.⚫Enjoy an Arabic dress photoshoot in the picturesque desert.⚫Take a tranquil Camel Ride, experiencing the desert like a true Bedouin.⚫Optional Quad Bike ride (available at an additional cost).⚫Feast on an International and BBQ Buffet Dinner with both vegetarian and non-vegetarian options.⚫Unlimited soft drinks and mineral water throughout the tour.⚫Optional Shisha session at your table (available at an additional cost).⚫Enjoy captivating performances including a Tanoura Show, Fire Show, and Belly Dance.⚫Return drop-off to your location.⚫Neat and Clean cars for batter experience.",
 },
  {
    _id: "204",
    img:  car4,
    img2: car2,
    img3: car3,
    img4: car1,
    productName: "Private Car with Driver Full Day",
    price: "700.00",
    color: "Transport",
    badge: false,
    des: "Enjoy a full day of luxury and convenience with a private car and professional chauffeur at your disposal.⚫Perfect for a day of shopping, business meetings, exploring the city's sights, or a stress-free journey.⚫Customize your itinerary, make multiple stops, and explore at your own pace.⚫Our priority is your comfort, convenience, and satisfaction.⚫Experience unparalleled luxury, professionalism, and punctuality with Irha Tourism.⚫Begin your adventure with a pickup from your location in a comfortable V8 Car.⚫Experience the thrill of Dune Bashing, navigating the towering sand dunes at high speed.⚫Enjoy an Arabic dress photoshoot in the picturesque desert.⚫Take a tranquil Camel Ride, experiencing the desert like a true Bedouin.⚫Optional Quad Bike ride (available at an additional cost).⚫Feast on an International and BBQ Buffet Dinner with both vegetarian and non-vegetarian options.⚫Unlimited soft drinks and mineral water throughout the tour.⚫Optional Shisha session at your table (available at an additional cost).⚫Enjoy captivating performances including a Tanoura Show, Fire Show, and Belly Dance.⚫Return drop-off to your location.⚫Limited to 6 passengers per car for a personalized experience.    ",
},
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    _id: 1001,
    img:  farari1,
    img2: farari2,
    img3: farari3,
    img4: farari4,
    productName: "Ferrari World",
    price: "295.00",
    color: "Park",
    badge: true,
    des: "Experience the Thrill of Ferrari World Abu Dhabi\n\nDiscover the exhilaration of Ferrari World Abu Dhabi, an indoor amusement park located on the vibrant Yas Island. Perfect for families seeking excitement and adrenaline-fueled fun, this ultimate playground brings the iconic Ferrari experience to life.\n\nUnleash Your Inner Speed Demon\nAt Ferrari World, prepare to experience the thrilling rush of speed like never before. Imagine the sheer exhilaration of accelerating to 250 km/h in just five seconds on the world's fastest roller coaster. It's a heart-pounding adventure that every thrill-seeker must experience.\n\nFulfill Your Ferrari Dreams\nEver dreamt of driving a Ferrari? At Ferrari World, that dream can become a reality. Buckle up and take a spin around the track with your family or alongside a Ferrari co-pilot, and feel the power of the prancing horse beneath you.\n\nFun for the Whole Family\nFerrari World isn’t just for adults—it’s the perfect destination for families. With a plethora of activities and adventures designed to keep kids entertained, there’s something for everyone to enjoy. From the Speed of Magic 4D movie to numerous outstanding shows and attractions, Ferrari World guarantees endless fun and excitement for visitors of all ages.\n\nBook Your Unforgettable Experience with Irha Tourism\nContact Irha Tourism to book your perfect experience at Ferrari World Abu Dhabi. Our unbeatable tour inclusions ensure a seamless and memorable visit:\n\nGeneral Admission ticket to Ferrari World Abu Dhabi\nAccess to 25 racing-themed rides, shows, and attractions\nPick-up & Drop-off Facility (if transportation option selected)\n",
  },
  {
    _id: 1002,
    img:  yas_Waterworld1,
    img2: yas_Waterworld2,
    img3: yas_Waterworld3,
    img4: yas_Waterworld4,
    productName: "Yas Waterworld",
    price: "295.00",
    color: "Park",
    badge: true,
    des: "Immerse yourself in a world of excitement and fun at Yas Waterworld Yas Island, Abu Dhabi—an iconic waterpark infused with Emirati charm. Enjoy over 40 exhilarating rides, slides, and attractions, promising an unforgettable adventure filled with thrills and excitement. Admission ticket grants access to Yas Waterworld Yas Island. Experience rides like the Dawwama, Liwa Loop, and the world’s first hydro-magnetic tornado waterslide, the Jebel Drop. Additional services such as pick-up and drop-off are available upon request. For bookings, call or message us on WhatsApp at 00971 50 799 9284. Duration: More than 3 hours Included in this package: Admission ticket granting access to Yas Waterworld Yas Island Experience the thrill of rides like the Dawwama, Liwa Loop, and the world's first hydro-magnetic tornado waterslide, the Jebel Drop For those seeking convenience, additional services such as pick-up and drop-off are available upon request.",
  },
  {
    _id: 1003,
    img:  dubai_frame1,
    img2: dubai_frame2,
    img3: dubai_frame3,
    img4: dubai_frame4,
    productName: "Dubai Frame",
    price: "55.00",
    color: "Park",
    badge: false,
    des: "Experience the awe-inspiring Dubai Frame, a monumental structure that encapsulates the past, present, and future of this vibrant city. Located in the heart of Dubai at Zabeel Park, this architectural marvel stands as a symbol of innovation and progress. Admission includes access to the Dubai Past Gallery, 360-degree views from the 150-meter high Sky Deck, walking across the Glass Bridge, and the Virtual Metropolis experience. The Dubai Frame offers panoramic views of both the historic old Dubai and the glittering new Dubai, including iconic landmarks like the Burj Khalifa. For bookings and inquiries, contact us via call or WhatsApp at 00971 50 799 9284. Tour Inclusions: Admission ticket to the spectacular Dubai Frame, Explore the Dubai Past Gallery, Enjoy 360-degree views from the 150-meter high Sky Deck, Walk across the breathtaking Glass Bridge, Peer into Dubai's future with the Virtual Metropolis experience, Pick-up & Drop Off Facility (Private Transportation option available). Additional Options: Customize your Dubai Frame experience with additional offerings. Additional Information: Venue Address: Zabeel Park Jogging Track, Za'abeel, Al Kifaf, Dubai, United Arab Emirates. Please ensure you bring a valid Photo ID/passport for entrance. Timings for transfers may vary based on traffic conditions and your location. Pets, food, and beverage are not permitted at the Dubai Frame. Children under 12 must be accompanied by an adult. Children under three and people with disabilities, along with two companions, enjoy free access to the Dubai Frame. Opening Hours: 09:00 AM to 09:00 PM (Everyday).",
  },
  {
    _id: 1004,
    img:  louvre_Museum1,
    img2: louvre_Museum2,
    img3: louvre_Museum3,
    img4: louvre_Museum4,
    productName: "Louvre Museum",
    price: " 100.00",
    color: "Park",
    badge: true,
    des: "Experience the extraordinary Louvre Abu Dhabi Universal Museum, a defining cultural landmark in Abu Dhabi and the UAE. The museum showcases artifacts from diverse civilizations, fostering deep understanding and reflection. Marvel at the awe-inspiring architecture, resembling a floating ivory structure on the tranquil expanse of the Persian Gulf. Encounter an array of art classics and masterpieces by globally acclaimed artists like Leonardo Da Vinci and Vincent Van Gogh. Tour Highlights: Access to the inaugural Universal Museum of the Arabian world Marvel at permanent exhibits tracing back to the 3rd century BC Witness classic masterpieces on loan from esteemed French institutions Transfers available (If chosen as an option) Essential Information: Kindly ensure you carry a valid ID or Passport during your visit. Child tickets are complimentary but require booking. Please Note: The Louvre Abu Dhabi is closed on Mondays.",
  },
  {
    _id: 1005,
    img:  future_m1,
    img2: future_m2,
    img3: future_m3,
    img4: future_m4,
    productName: "Future Museum",
    price: "150.00",
    color: "Theme Park",
    badge: true,
    des: "Step into a realm of boundless possibilities with your ticket to the Museum of the Future in Dubai. Dive into a journey through time and space as you explore what the world may hold 50 years from now. Situated along the illustrious Sheikh Zayed Road, this visionary institution delves into the profound impact of science and technology on societal evolution.\n\nHighlights:\n\n- Architectural marvel resembling a mesmerizing torus.\n- Interactive exhibits, immersive simulations, and inspiring narratives.\n- Arabic calligraphy bearing quotes from His Highness Sheikh Mohammed bin Rashid Al Maktoum.\n- Themes like health, education, environment, and happiness.\n\nInclusions:\n\n- Museum entry ticket.\n- Access to groundbreaking exhibits.\n- Transfers (Private Transportation available upon request).\n\nAdditional Information:\n\n- Booking Confirmation valid for specific date and time.\n- Convenient transfers provided from centrally located hotels and residences in Dubai city.\n- Operating hours: 10 am to 6 pm daily.\n- Children under 5 enter for free.",
  },
  {
    _id: 1006,
    img:  wild_wadi1,
    img2: wild_wadi2,
    img3: wild_wadi3,
    img4: wild_wadi4,
    productName: "Wild Wadi Water Park",
    price: "200.00",
    color: "Water Park",
    badge: false,
    des:"Embark on an unforgettable aquatic adventure at Wild Wadi Water Park, near the iconic Burj Al Arab in Dubai.⚫ Juha's Journey: relaxing river trip. ⚫ Adrenaline-pumping Flood River. ⚫ BurjSurj and Tantrum Alley water slides. ⚫ Riptide Flow-riders and wipe out rides for surfing enthusiasts. ⚫ Jumeirah Sceirah: a 33-meter free-fall water slide. ⚫  Wild Wadi Water Park operates from Monday to Sunday, with opening hours from 10:00 AM to 6:00 PM.",
  },
  {
    _id: 1007,
    img:  dubai_city1,
    img2: dubai_city2,
    img3: dubai_city3,
    img4: dubai_city4,
    productName: "Dubai City Tour Sharing",
    price: "150.00",
    color: "City Tour",
    badge: true,
    des:"Explore the wonders of Dubai with our exclusive tour package, offering an unforgettable journey through the heart of the UAE. Starting with pickup in a car, this tour covers historic and modern attractions. Highlights include: Old Dubai & Dubai Museum, Drive through Sheikh Zayed Road, Burj-al-Arab & Jumeirah Beach, Meena Bazar & Creek Side View, Atlantis The Palm Island, Souk Madinat Jumeirah, Jumeirah Mosque, Dubai Downtown, La-Mer Beach, King's Palace, Heritage Village, Dubai Marina, and Burj Khalifa.",
  },
  {
    _id: 1008,
    img:  dubai_city3,
    img2: dubai_city2,
    img3: dubai_city1,
    img4: dubai_city4,
    productName: "Dubai City Tour Private",
    price: "700.00",
    color: "City Tour",
    badge: true,
    des:"Explore the wonders of Dubai with our exclusive tour package, offering an unforgettable journey through the heart of the UAE. Starting with pickup in a private car, this tour covers historic and modern attractions. Highlights include: Old Dubai & Dubai Museum, Drive through Sheikh Zayed Road, Burj-al-Arab & Jumeirah Beach, Meena Bazar & Creek Side View, Atlantis The Palm Island, Souk Madinat Jumeirah, Jumeirah Mosque, Dubai Downtown, La-Mer Beach, King's Palace, Heritage Village, Dubai Marina, and Burj Khalifa.",
  },
  {
    _id: 1009,
    img:  desert_Safari1,
    img2: desert_Safari2,
    img3: desert_Safari3,
    img4: desert_Safari4,
    productName: "Desert Safari Private",
    price: "700.00",
    color: "Safari",
    badge: true,
    des: "Begin your adventure with a pickup from your location in a comfortable V8 Car. ⚫ Experience the thrill of Dune Bashing, navigating the towering sand dunes at high speed. ⚫ Enjoy an Arabic dress photoshoot in the picturesque desert.⚫Take a tranquil Camel Ride, experiencing the desert like a true Bedouin.⚫ Optional Quad Bike ride (available at an additional cost). ⚫ Feast on an International and BBQ Buffet Dinner with both vegetarian and non-vegetarian options.⚫ Unlimited soft drinks and mineral water throughout the tour.⚫Optional Shisha session at your table (available at an additional cost).⚫Enjoy captivating performances including a Tanoura Show, Fire Show, and Belly Dance.⚫ Return drop-off to your location.⚫Limited to 6 passengers per car for a personalized experience.",
  },
  {
    _id: 1010,
    img:  desert_Safari3,
    img2: desert_Safari4,
    img3: desert_Safari1,
    img4: desert_Safari2,
    productName: "Desert Safari Sharing",
    price: "250.00",
    color: "Safari",
    badge: true,
    des: "Begin your adventure with a pickup from your location in a comfortable V8 Car. ⚫ Experience the thrill of Dune Bashing, navigating the towering sand dunes at high speed. ⚫ Enjoy an Arabic dress photoshoot in the picturesque desert.⚫Take a tranquil Camel Ride, experiencing the desert like a true Bedouin.⚫ Optional Quad Bike ride (available at an additional cost). ⚫ Feast on an International and BBQ Buffet Dinner with both vegetarian and non-vegetarian options.⚫ Unlimited soft drinks and mineral water throughout the tour.⚫Optional Shisha session at your table (available at an additional cost).⚫Enjoy captivating performances including a Tanoura Show, Fire Show, and Belly Dance.⚫ Return drop-off to your location.⚫Limited to 6 passengers per car for a personalized experience.",
   },
  {
    _id: 1011,
    img:  abu_Dhabi_City1,
    img2: abu_Dhabi_City4,
    img3: abu_Dhabi_City3,
    img4: abu_Dhabi_City2,
    productName: "Abu Dhabi City Tour Private",
    price: "700.00",
    color: "City Tour",
    badge: true,
    des: "Begin your adventure with a pickup from your location in a comfortable V8 Car.⚫Experience the thrill of Dune Bashing, navigating the towering sand dunes at high speed.⚫Enjoy an Arabic dress photoshoot in the picturesque desert.⚫Take a tranquil Camel Ride, experiencing the desert like a true Bedouin.⚫Optional Quad Bike ride (available at an additional cost).⚫Feast on an International and BBQ Buffet Dinner with both vegetarian and non-vegetarian options.⚫Unlimited soft drinks and mineral water throughout the tour.⚫Optional Shisha session at your table (available at an additional cost).⚫Enjoy captivating performances including a Tanoura Show, Fire Show, and Belly Dance.⚫Return drop-off to your location.⚫Limited to 6 passengers per car for a personalized experience.",
  },
  {
    _id: 1012,
    img:  abu_Dhabi_City2,
    img2: abu_Dhabi_City4,
    img3: abu_Dhabi_City3,
    img4: abu_Dhabi_City1,
    productName: "Abu Dhabi City Tour Sharing",
    price: "200.00",
    color: "City Tour",
    badge: true,
    des: "Begin your adventure with a pickup from your location in a comfortable V8 Car.⚫Experience the thrill of Dune Bashing, navigating the towering sand dunes at high speed.⚫Enjoy an Arabic dress photoshoot in the picturesque desert.⚫Take a tranquil Camel Ride, experiencing the desert like a true Bedouin.⚫Optional Quad Bike ride (available at an additional cost).⚫Feast on an International and BBQ Buffet Dinner with both vegetarian and non-vegetarian options.⚫Unlimited soft drinks and mineral water throughout the tour.⚫Optional Shisha session at your table (available at an additional cost).⚫Enjoy captivating performances including a Tanoura Show, Fire Show, and Belly Dance.⚫Return drop-off to your location.⚫Neat and Clean cars for batter experience.",
  },
  {
    _id: 1013,
    img:  car1,
    img2: car4,
    img3: car3,
    img4: car2,
    productName: "Airport Private Departure",
    price: "100.00",
    color: "Transport",
    badge: false,
    des: "Private, door-to-door airport transfer for a stress-free departure.⚫Prompt pickup from your location, driver will contact you via WhatsApp or hold a nameplate for easy identification.⚫Flight tracking to ensure timely arrival and departure.⚫Clean cars and professional drivers for a luxurious experience.⚫Assistance with luggage for a smooth departure.",
 },

  {
    _id: 1014,
    img:  car2,
    img2: car4,
    img3: car3,
    img4: car1,
    productName: "Airport Private Arrival",
    price: "150.00",
    color: "Transport",
    badge: false,
    des: "Private, door-to-door airport transfer for a stress-free departure.⚫Prompt pickup from your location, driver will contact you via WhatsApp or hold a nameplate for easy identification.⚫Flight tracking to ensure timely arrival and departure.⚫Clean cars and professional drivers for a luxurious experience.⚫Assistance with luggage for a smooth departure.",
  },
  {
    _id: 1015,
    img:  car4,
    img2: car2,
    img3: car3,
    img4: car1,
    productName: "Private Car with Driver Full Day",
    price: "700.00",
    color: "Transport",
    badge: false,
    des: "Enjoy a full day of luxury and convenience with a private car and professional chauffeur at your disposal.⚫Perfect for a day of shopping, business meetings, exploring the city's sights, or a stress-free journey.⚫Customize your itinerary, make multiple stops, and explore at your own pace.⚫Our priority is your comfort, convenience, and satisfaction.⚫Experience unparalleled luxury, professionalism, and punctuality with Irha Tourism.⚫Begin your adventure with a pickup from your location in a comfortable V8 Car.⚫Experience the thrill of Dune Bashing, navigating the towering sand dunes at high speed.⚫Enjoy an Arabic dress photoshoot in the picturesque desert.⚫Take a tranquil Camel Ride, experiencing the desert like a true Bedouin.⚫Optional Quad Bike ride (available at an additional cost).⚫Feast on an International and BBQ Buffet Dinner with both vegetarian and non-vegetarian options.⚫Unlimited soft drinks and mineral water throughout the tour.⚫Optional Shisha session at your table (available at an additional cost).⚫Enjoy captivating performances including a Tanoura Show, Fire Show, and Belly Dance.⚫Return drop-off to your location.⚫Limited to 6 passengers per car for a personalized experience.    ",
 },
  {
    _id: 1016,
    img:  car3,
    img2: car2,
    img3: car4,
    img4: car1,
   productName: "Private Car with Driver Half Day",
    price: "250.00",
    color: "Transport",
    badge: true,
    des: "Enjoy half a day with a private car and professional chauffeur at your disposal.⚫Ideal for shopping, business meetings, city exploration, or a stress-free journey.⚫Freedom to create your own itinerary, make multiple stops, and explore at your own pace.⚫Our commitment is to your comfort, convenience, and satisfaction.⚫Experience luxury, professionalism, and punctuality with Irha Tourism.",
  },
  {
    _id: 1017,
    img:  warner_bros1,
    img2: warner_bros2,
    img3: warner_bros4,
    img4: warner_bros3,
    productName: "Warner Bros World Abu Dhabi",
    price: "295.00",
    color: "Theme Park",
    badge: false,
    des: "Explore the Warner Bros World Abu Dhabi, an indoor theme park where you can meet your favorite characters.⚫Enjoy thrilling rides and attractions inspired by classic Warner Bros films and TV shows.⚫Family-friendly with something for everyone.⚫Convenient pick-up and drop-off options available.⚫Tour Inclusions: Full access to Warner Bros World Abu Dhabi.⚫General Admission ticket to explore themed zones.⚫Pick-up and drop-off facilities.",
  },
  {
    _id: 1018,
    img:  miracle_garden1,
    img2: miracle_garden2,
    img3: miracle_garden4,
    img4: miracle_garden3,
    productName: "Miracle Garden Dubai",
    price: "99.00",
    color: "Theme Park",
    badge: false,
    des: "Experience the beauty of Dubai's Miracle Garden with over 45 million flowers.⚫Enjoy expertly curated trips to witness mesmerizing displays.⚫Private transfer options available upon request.⚫Entrance ticket to Miracle Garden.⚫Panoramic view of over 45 million flowers.⚫Private transfer upon request.",
  },
  {
    _id: 1019,
    img:  global1,
    img2: global2,
    img3: global4,
    img4: global3,
    productName: "Global Village Dubai",
    price: "25.00",
    color: "Theme Park",
    badge: false,
    des: "Explore cultures from 72 countries at Global Village Dubai.⚫Offers entertainment, shopping, and dining experiences.⚫Specially curated tour packages for unlimited fun.⚫Admission to Global Village.⚫Cultural entertainment.⚫Food, souvenirs, shopping, and rides (additional cost).⚫Pick-up and drop-off facility available.",
  },
  {
    _id: 1020,
    img:  ski_dubai1,
    img2: ski_dubai2,
    img3: ski_dubai4,
    img4: ski_dubai3,
    productName: "Ski Dubai",
    price: "225.00",
    color: "Theme Park",
    badge: false,
    des: "Enjoy skiing and snowboarding at Ski Dubai in the Mall of the Emirates.⚫Various activities including bobsled, zip line, and penguin encounter.⚫Top-notch instructors available for skiing and snowboarding lessons.⚫Access to Ski Dubai.⚫Equipment rental available at Snow Pro.⚫Penguin show and various thrilling activities.",
  },

];
// =================== PaginationItems End here =================
